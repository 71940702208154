import React from 'react';
import './Overview.scss';
import Card from './Elements/Card';
import OrderTable from './Tables/OrderTable';
import ProductTable from './Tables/ProductTable';
import PieChart from './Chart/PieChart';
import BarChart from './Chart/BarChart';
function Overview() {
  return (
    <div className='overview-components'>
      <div className="overview-container">
        <div className="cards-container">
          <Card />
        </div>

        <div className="chart-boxs">
          <BarChart/>
          <PieChart />
        </div>

        <div className="table-box">
          <ProductTable/>
          <OrderTable />
        </div>

      </div>
    </div>
  )
}

export default Overview