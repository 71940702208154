import React, { useState } from 'react';
import { Chart } from 'chart.js/auto'
import { Bar } from "react-chartjs-2";

const piedata = [
    {
        id: 1,
        year: 2016,
        usergain: 8000,
        userlost: 824,
    },
    {
        id: 2,
        year: 2017,
        usergain: 2800,
        userlost: 124,
    },
    {
        id: 3,
        year: 2018,
        usergain: 9700,
        userlost: 924,
    },
    {
        id: 4,
        year: 2019,
        usergain: 2800,
        userlost: 84,
    },
]


function BarChart() {
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
        datasets: [{
            label: 'User Chart',
            data: [65, 59, 80, 81, 26, 55, 40, 69, 75, 87, 78, 60],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
        }]
    };

    return (
        <div className="barchart-box">
            <Bar data={data} />
        </div>
    )
}

export default BarChart;