import React from 'react';
import './Card.scss';
import GroupsIcon from '@mui/icons-material/Groups';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


const Items = [
  {
    title: "TOTAL CUSTOMERS",
    count: "7.5k",
    icon: <GroupsIcon className='icon' />,
    growth: '19%',
    footer: "Since last month",
  },
  {
    title: "TOTAL MECHANICS",
    count: "1.5k",
    icon: <EngineeringIcon className='icon' />,
    growth: '5%',
    footer: "Since last month",
  },
  {
    title: "TOTAL EMPLOYEES",
    count: "84",
    icon: <PeopleAltIcon className='icon' />,
    growth: '2%',
    footer: "Since last month",
  },
  {
    title: "TOTAL PROFIT",
    count: "10M",
    icon: <CurrencyRupeeIcon className='icon' />,
    growth: '40%',
    footer: "Since last month",
  },
]

function Card() {
  return (
    <>

      {Items ?
        Items.map((item, index) => (
          <div className='card-item' key={index}>

            <div className="left-box">
              <p className="heading">{item.title}</p>
              <h3 className="count">{item.count}</h3>
              <p className="footer"><span><TrendingUpIcon className='icon' /> {item.growth}</span> {item.footer}</p>
            </div>
            <div className="right-box">

              <div className={`icon-box icon-box${index+1}`}>
                {item.icon}
              </div>

            </div>

          </div>
        )) : null
      }
    </>
  )
}

export default Card