export const Auth = "Authentication-Token";

//const Base_Url = "https://admin.roadserve.in/";
const Base_Url = "https://www.roadserve.org/";
export const Login_Url = `${Base_Url}admin-login`;
export const Register_Url = `${Base_Url}admin-register`;
export const AdminDetails_Url = `${Base_Url}get-admin-details`;
export const UpdateProfile_Url = `${Base_Url}update-admin-profile`;
export const UpdateDP_Url = `${Base_Url}update_admin_picture`;
export const Cng_TL_Emp_Url = `${Base_Url}change-tl-of-employees?`;


