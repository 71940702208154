import React, { useContext, useState } from 'react';
import './SideBar.scss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { Items } from '../../Datas/NavData';
import LogoutIcon from '@mui/icons-material/Logout';
import { Auth } from '../../API/Api';
import { useLocation } from 'react-router-dom';
import { GlobalStore } from '../../App';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import logo from '../../Images/logo.png';

function SideBar(props) {
  const { getAuthlog } = useContext(GlobalStore);
  const param = useLocation();
  const [itemName, setItemName] = useState(param.pathname);

  const logout_handler = () => {
    localStorage.removeItem(Auth);
    const store = localStorage.getItem(Auth);
    getAuthlog(store);
  }

  return (
    <div className={`sidebar-container ${props.postnavCls}`}>

      <div className="naigation-bar">
        <img src={logo} alt="logo" className='icon-logo' />
        <MenuOpenIcon className='icon' onClick={() => props.getnavCls('hide')} />

      </div>

      <div className="inner-container">
        <div className="header-box">
          <div className="text-box">
            <h5>Roadserve</h5>
            <p className="para">Deshbord</p>
          </div>
          <div className="icon-box">
            <KeyboardArrowUpIcon className='icon' />
            <KeyboardArrowDownIcon className='icon' />
          </div>
        </div>
      </div>

      <div className="blank-border"></div>

      <div className="navigate-item">

        {
          Items.map((item, index) => (
            <div className={`item-box ${itemName === item.link ? "back-clr" : null}`} key={index} onClick={() => setItemName(item.link)}>
              {item.icon}
              <Link to={item.link} className='links'>{item.name}</Link>
            </div>
          ))
        }

        <div className="item-box">
          <LogoutIcon className='icon' />
          <p className='links' onClick={logout_handler}>Logout</p>
        </div>


      </div>

    </div>
  )
}

export default SideBar