import React, { useContext, useState } from 'react';
import './Login.scss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Login_Url, Auth } from '../../API/Api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { GlobalStore } from '../../App';
axios.defaults.baseURL = "http://103.168.173.64:8000/";
axios.defaults.headers.common['Authentication-Token'] = Auth;


const schema = yup.object({
  email_phone: yup.string().required(),
  password: yup.string().required(),
}).required();


function Login() {
  const navigate = useNavigate();
  const { getAuthlog } = useContext(GlobalStore);
  const [spinner, setSpinner] = useState(false);

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    setSpinner(true);
    const Data = {
      email_phone: data.email_phone,
      password: data.password,
      ip: '192.0.2.1'
    }
    axios.post(Login_Url, Data)
      .then((res) => {
        // console.log('Login Respose : ', res)
        localStorage.setItem(Auth, res.data.token);
        getAuthlog(localStorage.getItem(Auth))
        Swal.fire(
          'Thank You!',
          'Login Success',
          'success'
        )
        navigate('/')
        setSpinner(false)
      })
      .catch((err) => {
        console.log('Login Error : ', err)
        Swal.fire(
          'Opps...Sorry!',
          'Login Failed, Please Try Again',
          'error'
        )
        setSpinner(false)
      })
  }



  return (
    <div className='login-components'>
      <Card className='form'>
        <h3 className='login-head'>Login</h3>
        <form onSubmit={handleSubmit(onSubmit)}>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email or Number</Form.Label>
            <Form.Control type="text" placeholder="Enter email or number" name='email_phone' {...register("email_phone")} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" name='password' {...register("password")} />
          </Form.Group>


          <Button variant="primary" type="submit" className='btn'>
            {spinner ? 'Login...' : 'Login'}
          </Button>

        </form>
        <p className="para">Don't have account? <span onClick={() => navigate('/register')}>Register</span></p>
      </Card>
    </div>
  )
}

export default Login;