import React, {createContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth } from "./API/Api";
import OverviewPage from "./Pages/OverviewPage";
import CoustomerPage from "./Pages/CoustomerPage";
import EmployeesPage from "./Pages/EmployeesPage";
import MechanicsPage from "./Pages/MechanicsPage";
import AppReportPage from "./Pages/AppReportPage";
import WhatsappPage from "./Pages/WhatsappPage";
import AccountPage from "./Pages/AccountPage";
import SettingsPage from "./Pages/SettingsPage";
import LoginPage from "./Pages/AuthPage/LoginPage";
import RegisterPage from "./Pages/AuthPage/RegisterPage";
import Authentication from "./Authentication/Authentication";
import SideBar from "./Components/SideBar/SideBar";
import Navbar from "./Components/Navbar/Navbar";
export const GlobalStore = createContext();

function App() {
  const [authlog, setAuthlog] = useState(localStorage.getItem(Auth));
  const [navCls, setNavCls] = useState();
  
  return (
    <div className="App">
      <Router>
        <GlobalStore.Provider value={{
          // send data
          PostAuthlog : authlog,

          // receved dara
          getAuthlog : setAuthlog,

        }}>

          {
            authlog ? <SideBar postnavCls={navCls} getnavCls={setNavCls}/> : null
          }
          {
            authlog ? <Navbar getnavCls={setNavCls}/> : null
          }

          <Routes>

            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />

            <Route element={<Authentication />}>
              <Route path="/" element={<OverviewPage />} />
              <Route path="/customer" element={<CoustomerPage />} />
              <Route path="/employees" element={<EmployeesPage />} />
              <Route path="/mechanics" element={<MechanicsPage />} />
              <Route path="/app-report" element={<AppReportPage />} />
              <Route path="/whatsapp" element={<WhatsappPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/settings" element={<SettingsPage />} />
            </Route>
          </Routes>

        </GlobalStore.Provider>
      </Router>
    </div>
  );
}
export default App;
