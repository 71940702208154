import React, {useState, useContext} from 'react';
import './Navbar.scss';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dpimg from '../../Images/logo.png';

import { Auth } from '../../API/Api';
import { GlobalStore } from '../../App';

function Navbar(props) {
  const [anchorEl, setAnchorEl] = useState (null);
  const { getAuthlog } = useContext(GlobalStore);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout_handler = () => {
    localStorage.removeItem(Auth);
    const store = localStorage.getItem(Auth);
    getAuthlog(store);
  }

  return (
    <div className='navbar-container'>
      <div className="menu-btn-box">
        <MenuOpenIcon className='menu-btn' onClick={() => props.getnavCls("visible")} />
      </div>

      <div className="navbar-right-box">
        <div className="searchingbox">
          {/* <SearchIcon className='search-btn'/>
          <input type="text" className='input-field'/> */}
        </div>

        <div className="profile-container">
          <AccountCircleIcon className='icons' onClick={handleClick} />
          {/* <img src={dpimg} className='dp-img' onClick={handleClick} /> */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={logout_handler}>Logout</MenuItem>
          </Menu>
        </div>

      </div>
    </div>
  )
}

export default Navbar