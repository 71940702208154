import React from 'react';
import Customers from '../PagesComponents/Customers/Customers';
import Helmet from 'react-helmet';

function CoustomerPage() {
  return (
    <>
      <Helmet>
        <title>Customers | Roadserve</title>
      </Helmet>
      <Customers />
    </>
  )
}

export default CoustomerPage