import React, { useState } from 'react';
import { Chart } from 'chart.js/auto'
import { Doughnut } from "react-chartjs-2";

const piedata = [
  {
    id: 1,
    year: 2016,
    usergain: 8000,
    userlost: 824,
  },
  {
    id: 2,
    year: 2017,
    usergain: 2800,
    userlost: 124,
  },
  {
    id: 3,
    year: 2018,
    usergain: 9700,
    userlost: 924,
  },
  {
    id: 4,
    year: 2019,
    usergain: 2800,
    userlost: 84,
  },
]

function PieChart() {
  const data = {
    labels: ['Customers', 'Mechanics', 'Users'],
    datasets: [{
      label: 'My First Dataset',
      data: [659, 178, 790],
    }]
  };


  return (
    <div className="piechart-box">
      <Doughnut data={data} />
    </div>

  )
}


export default PieChart;
