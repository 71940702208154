import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '../../API/Api';
import Register from '../../PagesComponents/Register/Register';
import { Helmet } from 'react-helmet';

function RegisterPage() {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem(Auth);
    if (auth) {
      navigate('/');
    }
  }, [])
  return (
    <>
      <Helmet>
        <title>Register | Roadserve</title>
      </Helmet>

      <Register />

    </>
  )
}

export default RegisterPage