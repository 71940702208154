import React from 'react';
import './Customers.scss';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

function Customers() {
  return (
    <div className='customer-components'>
        <div className="customer-container">
            <div className="top-container">
                <h3 className="heading">Customers</h3>
                <div className="search-bar">
                    <div className="search-box">
                        <SearchIcon className='icon'/>
                        <input type="text" className='input-box'/>
                    </div>

                    <div className="add-btn">
                        <AddIcon className='icon'/>Add</div>
                </div>

            </div>

            
        </div>
    </div>
  )
}

export default Customers