import React, { useState } from 'react';
import './Register.scss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Swal from 'sweetalert2';
import { Register_Url } from '../../API/Api';

const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().required(),
    contact_number: yup.string().required(),
    office: yup.string().required(),
    password: yup.string().required(),
}).required();


function Register() {
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);

    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        setSpinner(true);
        const Data = {
            name: data.name,
            email: data.email,
            contact_number: data.contact_number,
            office: data.office,
            password: data.password,
            ip: '192.0.2.1'
        }
        if (data.contact_number.length === 10) {
            axios.post(Register_Url, Data)
                .then((res) => {
                    setSpinner(false);
                    Swal.fire(
                        'Thank You!',
                        'Register Success, Please Login',
                        'success'
                    )
                    navigate('/login');
                })
                .catch((err) => {
                    console.log('Register Error : ', err)
                    setSpinner(false);
                    Swal.fire(
                        'Opps...Sorry!',
                        'Register Failed, Please Try Again',
                        'error'
                    )
                })
        } else {
            alert("Type valid number")
        }

    }

    return (
        <div className='register-components'>
            <Card className='form'>
                <h3 className='login-head'>Register</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Full name" name='name' {...register("name")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email address" name='email' {...register("email")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Number</Form.Label>
                        <Form.Control type="number" placeholder="Contact number" name='contact_number' {...register("contact_number")} />
                    </Form.Group>

                    <Form.Select name='office' {...register("office")}>
                        <option defaultValue='delhi'>Select Office</option>
                        <option value='delhi'>Delhi</option>
                        <option value='noida'>Noida</option>
                    </Form.Select>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" name='password' {...register("password")} />
                    </Form.Group>

                    <Button variant="primary" type="submit" className='btn'>
                        {spinner ? 'Register...' : 'Register'}
                    </Button>

                </form>
                <p className="para">You have account? <span onClick={() => navigate('/login')}>Login</span></p>
            </Card>
        </div>
    )
}

export default Register