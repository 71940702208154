
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Groups2Icon from '@mui/icons-material/Groups2';

export const Items = [
    {
        name : 'Overview',
        link : '/',
        icon : <SignalCellularAltIcon className='icon'/>,
    },
    {
        name : 'Customers',
        link : '/customer',
        icon : <Groups2Icon className='icon'/>,
    },
    {
        name : 'Employees',
        link : '/employees',
        icon : <PeopleAltIcon className='icon'/>,
    },
    {
        name : 'Mechanics',
        link : '/mechanics',
        icon : <EngineeringIcon className='icon'/>,
    },
    {
        name : 'App Report',
        link : '/app-report',
        icon : <AppSettingsAltIcon className='icon'/>,
    },
    {
        name : 'Whatsapp',
        link : '/whatsapp',
        icon : <WhatsAppIcon className='icon'/>,
    },
    {
        name : 'Account',
        link : '/account',
        icon : <ManageAccountsIcon className='icon'/>,
    },
    {
        name : 'Settings',
        link : '/settings',
        icon : <SettingsIcon className='icon'/>,
    },
]